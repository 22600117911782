import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { pinia } from '@/plugins/pinia'
import { useSessionStore } from '@/store/Session'

Vue.use(VueRouter)

const Router = new VueRouter({
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

Router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth)
  const sessionStore = useSessionStore(pinia)

  if (requiresAuth && !sessionStore.isAuthenticated) next({ path: '/auth/login', query: { next: to.fullPath } })
  else if (requiresNoAuth && sessionStore.isAuthenticated) next('/')
  else next()
})

export default Router
