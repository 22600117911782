import {defineStore} from 'pinia'
import {emitirSnack} from '@/store/GlobalComponents'
import router from '@/router'

export const useSessionStore = defineStore('session', {
  state: () => ({
    token: null,
    time: Date.now(),
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    payload: (state) => {
      if (!state.token) return null
      const partes = state.token.split('.')
      if (partes.length !== 3) return null
      try {
        return JSON.parse(window.atob(partes[1]))
      } catch (e) {
        return null
      }
    },
  },
  actions: {
    logout() {
      if (this.token) {
        this.token = null
        emitirSnack({color: 'success', text: 'Você foi desconectado'})
      }
      return router.replace({name: 'login'})
    },
  },
})

export const restoreSavedSession = (pinia = null) => {
  const value = localStorage.getItem('pinia_session')
  const store = useSessionStore(pinia)
  if (value) store.token = value
  store.$subscribe((mutation, state) => {
    if (state.token) localStorage.setItem('pinia_session', state.token)
    else localStorage.removeItem('pinia_session')
  }, { detached: true })
}
