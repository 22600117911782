import JsonPackage from '../../package.json'

/**
 * Limpa a localStorage se a versão do aplicativo mudar
 */
export const checkVersion = () => {
  const x = localStorage.getItem('version');
  const y = localStorage.getItem('app_name');
  if ((!x || x !== JsonPackage.version) || (!y || y !== JsonPackage.name)) {
    localStorage.clear();
    localStorage.setItem('version', JsonPackage.version);
    localStorage.setItem('app_name', JsonPackage.name);
  }
}
