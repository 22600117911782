import {defineStore} from 'pinia'

export const useConfigStore = defineStore('config', {
  state: () => ({
    configuracoes: null
  }),
  actions: {
    async carregarConfiguracoes() {
      const {httpSilent} = await import('@/plugins/axios')
      const cache = localStorage.getItem('pinia_config')
      const promise = httpSilent.get('/configuracoes')
      if (cache) {
        this.configuracoes = JSON.parse(cache)
        promise.then(i => this.configuracoes = i.data.configuracoes)
      } else {
        const {data} = await promise
        this.configuracoes = data.configuracoes
      }
      localStorage.setItem('pinia_config', JSON.stringify(this.configuracoes))
    },
  }
})
